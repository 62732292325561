export const items = [
  {
    id: "a",
    category: "Recruitment Website",
    title: "Fullstack App - MERN",
    title2: "MongoDB, Express, React, Node, REST API",
    // title3: "Private Github repo access available on request",
    info: "This fullstack app offers three distinct account types - jobseeker, employer, and site admin - each with its own unique experience and set of permissions.",
    livelink: "https://tda-graduates.onrender.com",
    reponame: "Frontend Repository",
    repolink: "https://github.com/PhantomQuad/TDA-frontend/",
    reponame2: "Backend Repository",
    repolink2: "https://github.com/PhantomQuad/TDA-backend/",
    backgroundColor: "#814A0E",
  },
  {
    id: "b",
    category: "Weather App",
    title: "7 Day Forecast",
    title2: "HTML, JavaScript, Bootstrap, CSS, 3rd Party API",
    info: "A user-friendly, weather forecasting tool that allows users to quickly and easily check the weather forecast for today and the next 7 days for any city.",
    livelink: "https://weather-app-hen6.onrender.com/",
    reponame: "Repository",
    repolink: "https://github.com/PhantomQuad/weather-app/",
    backgroundColor: "#FA6779",
  },
  {
    id: "c",
    category: "Social Site",
    title: "Create a social profile",
    title2: "React, JavaScript, CSS, Local storage",
    info: "The aim of this project was to produce a front end only social media stream that consist of a series of social card components displaying posts by a user.",
    livelink: "https://project-social.onrender.com/",
    reponame: "Repository",
    repolink: "https://github.com/PhantomQuad/Project-Social/",
    backgroundColor: "#282F49",
  },
  {
    id: "d",
    category: "Adventure Game",
    title: "OOP Text based game",
    title2: "HTML, JavaScript, CSS",
    info: "I have created a text-based adventure game using JavaScript and object-oriented programming principles. The game consists of three classes: Room, Character, and Enemy.",
    livelink: "https://phantomquad.github.io/Project-Game/",
    reponame: "Repository",
    repolink: "https://github.com/PhantomQuad/Project-Game/",
    backgroundColor: "#959684",
  },
  {
    id: "e",
    category: "The Fitness Quiz?",
    title: "Multiple choice quiz",
    title2: "HTML, JavaScript, Bootstrap, CSS",
    info: "This code is a JavaScript quiz website using Bootstrap. It has functions for starting the quiz, navigating questions, calculating results, and displaying them.",
    livelink: "https://phantomquad.github.io/Project-JS-Quiz/",
    reponame: "Repository",
    repolink: "https://github.com/PhantomQuad/Project-JS-Quiz/",
    backgroundColor: "#5DBCD2",
  },
  {
    id: "f",
    category: "Ceaser Cipher",
    title: "Encode and decode text strings",
    title2: "HTML, JavaScript, Bootstrap, CSS",
    info: "This Caesar Cipher project uses Bootstrap and JavaScript to convert user's message based on chosen encryption/decryption and key values.",
    livelink: "https://phantomquad.github.io/Project-Cipher/",
    reponame: "Repository",
    repolink: "https://github.com/PhantomQuad/Project-Cipher/",
    backgroundColor: "#8F986D",
  },
];

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
